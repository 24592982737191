import { TheNightlySection } from '@news-mono/common'
import { QueryString, StaticPageRoute } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import {
    RESULT_SORT_PARAM,
    theNightlySearchFilterHeaderOverride,
    TOPIC_FILTER_PARAM,
} from '@news-mono/component-library'
import xss from 'xss'

interface SearchQuery extends QueryString {
    search: string
    page: number
    when: string
    sort: string
    topics: string
}

function queryHasParam(
    query: QueryString,
    param: string,
): query is SearchQuery {
    if (query[param] && typeof query[param] === 'string') {
        return true
    }
    return false
}

export const createSavedPublicationPage =
    (query: QueryString): StaticPageRoute<TheNightlySection> =>
    ({ getAdTargeting, config }) => {
        const adTargeting = getAdTargeting('search', 'default', 'search')
        const canonicalUrl = config.publicUrl + '/saved-articles'

        const sortValue = queryHasParam(query, RESULT_SORT_PARAM)
            ? xss(query[RESULT_SORT_PARAM])
            : undefined
        const topicsValue = queryHasParam(query, TOPIC_FILTER_PARAM)
            ? xss(query[TOPIC_FILTER_PARAM])
            : undefined
        const pageValue = queryHasParam(query, 'page') ? +query.page : 1

        return {
            kind: 'page',
            heading: 'Saved Articles',
            hideHeading: true,
            pageType: 'static',
            pageMeta: {
                title: 'Saved articles',
                description: 'Manage your saved articles and videos here.',
                link: [{ rel: 'canonical', href: canonicalUrl }],
                meta: [
                    {
                        property: 'robots',
                        content: 'noindex, nofollow',
                    },
                ],
            },
            section: 'default',
            adTargeting,
            compositions: [
                layout.composition({
                    type: 'luna',
                    props: {
                        hasAfterAside: false,
                    },
                    contentAreas: {
                        beforeAside: [
                            layout.component({
                                type: 'saved-articles',
                                props: {
                                    sortBy: sortValue,
                                    topics: topicsValue,
                                    paging: {
                                        page: pageValue,
                                        pageSize: 20,
                                    },
                                },
                            }),
                        ],
                        aside: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    horizontalSpacing: 'outerMargin',
                                    horizontalSpacingBreakpoint: 'sm',
                                    stickyOffset:
                                        'calc(var(--stickyHeaderHeight))',
                                },
                                contentAreas: {
                                    main: [
                                        layout.nestedComposition({
                                            type: 'component-overrider',
                                            props: {
                                                override: () =>
                                                    theNightlySearchFilterHeaderOverride,
                                            },
                                            contentAreas: {
                                                children: [
                                                    layout.nestedComposition({
                                                        type: 'saved-articles-filters',
                                                        props: {},
                                                        contentAreas: {
                                                            trailingContent: [
                                                                layout.component(
                                                                    {
                                                                        type: 'ad-unit',
                                                                        props: {
                                                                            adType: 'inline',
                                                                            noticePosition:
                                                                                'above-center',
                                                                            slot: {
                                                                                id: 'sidebar',
                                                                                size: 'above1280Mrec',
                                                                            },
                                                                        },
                                                                    },
                                                                ),
                                                            ],
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                        afterAside: [],
                    },
                }),
            ],
        }
    }
