import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TheSeatsWidget } from './TheSeatsWidget'

export const SeatWidgetRegistration = createRegisterableComponent(
    'election-seat-widget',
    () => {
        return <TheSeatsWidget />
    },
)
