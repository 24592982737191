import styled from '@emotion/styled'
import { IconSpinner } from '../../../icons'
import { IconChevronRightTN } from '../../../icons/IconChevronRightTN/IconChevronRightTN'
import { breakpoint, calcRem } from '../../../__styling'

export const TheRaceWidgetContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(8),
    alignSelf: 'stretch',

    padding: calcRem(16),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})

// TheRace Widget Header Styled Components

export const TheRaceWidgetHeaderTitle = styled('h2')({
    textAlign: 'right',
    margin: 0,

    fontFamily: 'Inter',
    fontSize: calcRem(40),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: calcRem(48),
    letterSpacing: calcRem(-1.2),
})

export const TheRaceWidgetChevron = styled(IconChevronRightTN)({})

// TheRace Widget Bar Graph Styled Components

export const TheRaceWidgetGraphContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: calcRem(8),
    alignSelf: 'stretch',
    position: 'relative',

    minHeight: calcRem(200),
})

export const TheRaceWidgetGraphDataContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(16),
    flex: '1 0 0',
})

export const TheRaceWidgetSpinnerContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: calcRem(8),

    width: '100%',
    minHeight: calcRem(200),
    justifyContent: 'center',
})

export const StyledRaceSpinner = styled(IconSpinner)({
    color: '#000',
    width: calcRem(40),
    height: calcRem(40),
})
