import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { WebLink } from '@news-mono/web-common'
import { getComponentTheme } from '../../../__styling'

export interface ElectionTitleButtonProps {
    to?: string
    children?: React.ReactNode
}

export const ElectionTitleButton = ({
    to,
    children,
}: ElectionTitleButtonProps) => {
    const Container = to ? ElectionTitleButtonContainer : ElectionTitleContainer

    return <Container to={to || ''}>{children}</Container>
}

// Container object, handles the background colours and the hover, & onClick!
const ElectionTitleContainer = styled('div')(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',

        // Styling for hover / active / focus
        backgroundColor: electionTheme.button.title.backgroundColor,
    }
})
const ElectionTitleButtonContainer = styled(WebLink)(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        color: '#000',
        textDecoration: 'none',

        // Styling for hover / active / focus
        backgroundColor: electionTheme.button.title.backgroundColor,

        '&:hover, &:active, &:focus': {
            backgroundColor: electionTheme.button.title.hoverColor,
            cursor: 'pointer',
            color: '#000',
            textDecoration: 'none',
        },
    }
})
