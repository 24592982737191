import {
    CurationV4ExpandedResponseDTO,
    EditionListV4ResponseDTO,
    ListPublicationWithEntriesV4DTO,
} from '@west-australian-newspapers/publication-types'
import { EditorialType, getEditorialType } from '../../templates'
import {
    ContentDataTypes,
    mapListPublication,
    PublicationCardItemWithoutVideo,
} from '@news-mono/web-common'
import { format, subHours } from 'date-fns'
import { UseQueryResult } from 'react-query'

export interface NewsTickerItem {
    id: string
    text: string
    link: string
    type?: EditorialType
    withGradientBackground: boolean
    kind: string
    primaryTopic: string
    source: string
    heading: string
}

export const getNewsTickerItems = (
    latestEditionThresholdInHour: number,
    maxTotalOfNewsTickerItem: number,
    newsTickerSources: UseQueryResult[],
) => {
    const isLoading = newsTickerSources.some((source) => source.isLoading)

    if (!isLoading && newsTickerSources) {
        const newsTickerCurationResult = newsTickerSources[0]
            .data as CurationV4ExpandedResponseDTO

        const mappedNewsTickerItems: PublicationCardItemWithoutVideo[] =
            newsTickerCurationResult.articles.map(
                (listPublication: ListPublicationWithEntriesV4DTO) =>
                    mapListPublication(listPublication),
            )

        let newsTickerItemsFromCuration = combineArticlesAndMetadataHeadlines(
            mappedNewsTickerItems,
            newsTickerCurationResult.metadata,
        )

        // fill in with top 5 items if the feature flag is on (top stories result is not empty)
        // and the news ticker hasn't been curated / empty
        const topStoriesCuration = newsTickerSources[1]
            .data as CurationV4ExpandedResponseDTO

        if (topStoriesCuration && newsTickerItemsFromCuration.length === 0) {
            const mappedTopStories: PublicationCardItemWithoutVideo[] =
                topStoriesCuration.articles.map(
                    (listPublication: ListPublicationWithEntriesV4DTO) =>
                        mapListPublication(listPublication),
                )

            const topStoriesAsNewsTickerItems =
                getNewsTickerItemFromArticles(mappedTopStories)

            newsTickerItemsFromCuration = newsTickerItemsFromCuration.concat(
                topStoriesAsNewsTickerItems.slice(0, maxTotalOfNewsTickerItem),
            )
        }

        // append latest edition
        const latestEdition = newsTickerSources[2]
            .data as EditionListV4ResponseDTO

        if (latestEdition) {
            const editionNewsTicker = getLatestEditionNewsTicker(
                latestEdition,
                latestEditionThresholdInHour,
            )

            if (editionNewsTicker)
                newsTickerItemsFromCuration.push(editionNewsTicker)
        }

        // prioritise breaking news over everything else
        const breakingNewsItem = newsTickerItemsFromCuration.find(
            (newsTicker) => newsTicker.type === EditorialType.Breaking,
        )

        const finalNewsTickerItems = breakingNewsItem
            ? [breakingNewsItem]
            : newsTickerItemsFromCuration

        return finalNewsTickerItems
    }

    return undefined
}

const getLatestEditionNewsTicker = (
    editionList: EditionListV4ResponseDTO,
    latestEditionThresholdInHour: number,
): NewsTickerItem | undefined => {
    const now = new Date()
    const latestPublicationTimeThreshold = subHours(
        now,
        latestEditionThresholdInHour,
    )
    const latestEdition = editionList.documents.filter(
        (edition) =>
            new Date(edition.publicationDate) > latestPublicationTimeThreshold,
    )

    if (latestEdition && latestEdition.length > 0) {
        const editionText = `LATEST EDITION: ${format(
            new Date(latestEdition[0].publicationDate),
            'dd MMMM yyyy',
        )} out now`

        const editionLink = latestEdition[0].editionWebUrl
        return {
            id: latestEdition[0].id,
            withGradientBackground: false,
            text: editionText,
            link: editionLink,
            kind: 'edition',
            heading: latestEdition[0].title,
            primaryTopic: '',
            source: latestEdition[0].source,
        }
    }

    return undefined
}

const combineArticlesAndMetadataHeadlines = (
    newsTickerArticles: PublicationCardItemWithoutVideo[],
    metadata: Record<string, string>,
): NewsTickerItem[] => {
    const newsTickerList = getNewsTickerItemFromArticles(newsTickerArticles)

    // Replace newsTickerList elements with metadata headlines, if provided
    Object.keys(metadata).forEach((key) => {
        const match = key.match(/news-ticker-headline-(\d+)/)
        if (match) {
            const index = parseInt(match[1], 10)
            const replacementHeadline = metadata[key]
            if (
                index >= 0 &&
                index < newsTickerList.length &&
                replacementHeadline
            ) {
                newsTickerList[index].text = replacementHeadline
            }
        }
    })

    return newsTickerList
}

const getNewsTickerItemFromArticles = (
    newsTickerItems: PublicationCardItemWithoutVideo[],
) => {
    return newsTickerItems.map((article) => {
        const editorialType = getEditorialType(article, true)

        return {
            id: article.id,
            text: article.shortHeadline,
            link: article.link,
            type: editorialType,
            withGradientBackground: editorialType === EditorialType.Breaking,
            primaryTopic: article.primaryTopic.id,
            kind: article.publicationKind,
            source: article.source,
            heading: article.headline,
        }
    })
}

export const getNewsTickerContext = (newsTickerItems: NewsTickerItem[]) => {
    const newsTickerTextCollection = newsTickerItems
        ? newsTickerItems.map((newTickerItem) => newTickerItem.text)
        : []

    const newsTickerContext = {
        timestamp: Date.now(),
        componentType: 'news-ticker',
        componentRenderPath: '',
        dataDefinition: {
            type: 'news-ticker',
            total: newsTickerTextCollection.length,
            pageSize: 1,
        } as ContentDataTypes,
        collectionCardCount: newsTickerTextCollection.length,
    }
    return { newsTickerContext }
}
