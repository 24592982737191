import React from 'react'
import { TopParty } from '../data'
import {
    TheRaceBarIndicatorContainer,
    TheRaceBarIndicator,
    TheRacePartyInnerRowContainer,
    TheRacePartyRowContainer,
    StyledRaceElectionText,
    StyledRaceAmountText,
    StyledRaceStar,
} from './TheRaceRow.styled'

export interface TheRaceRowProps {
    party: TopParty
    percentPerSeat: number
    seatsToWin: number
}

export const TheRaceRow = ({
    party,
    percentPerSeat,
    seatsToWin,
}: TheRaceRowProps) => {
    const starIcon = require('./TheRaceRowStar.svg')
    const colors = party.colors

    const predictedPercent = percentPerSeat * party.seatsPredicted
    const percent = percentPerSeat * party.seats

    const isFull = predictedPercent >= 100

    //In the unlikely event that a party gains a vast majority seats, we need to unsure that the seat count text is visible
    const overlapsPrimaryBar = percent >= 97

    const isWinner = party.seats >= seatsToWin

    const BarComponent = (
        <TheRaceBarIndicatorContainer
            isWinner={isWinner}
            partyColor={colors.pale10}
        >
            <TheRaceBarIndicator
                widthPercent={predictedPercent}
                partyColor={colors.pale20}
            />
            <TheRaceBarIndicator
                widthPercent={percent}
                partyColor={colors.primary}
            >
                {isWinner && <StyledRaceStar src={starIcon} />}
            </TheRaceBarIndicator>
            <StyledRaceAmountText
                barPercent={predictedPercent}
                isWinner={isWinner}
                partyColor={colors.dark}
                overlapsPrimaryBar={overlapsPrimaryBar}
            >
                {party.seatsPredicted}
            </StyledRaceAmountText>
        </TheRaceBarIndicatorContainer>
    )

    return (
        <TheRacePartyRowContainer key={party.code}>
            <TheRacePartyInnerRowContainer>
                <StyledRaceElectionText
                    isWinner={isWinner}
                    partyColor={colors.dark}
                >
                    {party.name}
                </StyledRaceElectionText>
                {BarComponent}
            </TheRacePartyInnerRowContainer>
            {/* <SampleMiniHorseShoeData /> TODO: Re-enable this when it's done */}
        </TheRacePartyRowContainer>
    )
}
