import { TheNightlySection } from '@news-mono/common'
import {
    IconChevronUpTN,
    metrics,
    theNightlyTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import {
    Topic,
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import H from 'history'
import React from 'react'

const socialImage = require('./assets/social-image.jpg')
const image = require('./assets/leadership-edition-2024.png')

type createTheNightlyOnLeadershipPageArgs = {
    baseUrl: string
    topic: Topic
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
}

export function createTheNightlyOnLeadershipPage({
    topic,
    section,
    getAdTargeting,
    baseUrl,
    location,
}: createTheNightlyOnLeadershipPageArgs): PageType<TheNightlySection> {
    const title = topic.seoTitle || topic.title
    const canonicalUrl = `${baseUrl}${location.pathname}`

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )
    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    return {
        kind: 'page',
        heading: 'The Nightly on Leadership',
        pageType: 'topic',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title,
            description:
                topic.seoDescription || `The latest in ${topic.seoTitle}`,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content: `${topic.seoTitle || topic.title} | The Nightly`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:image',
                    content: socialImage,
                },
                {
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    name: 'twitter:image',
                    content: socialImage,
                },
            ],
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth: '100%',
                    horizontalGutters: false,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacingBreakpoint: 'sm',
                                horizontalSpacing: 'outerMargin',
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'md',
                                            containerWidth:
                                                metrics.thenightly.siteMetrics
                                                    .mainContentWidth,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'nightly-special-edition',
                                                    props: {
                                                        title: 'Leadership',
                                                        teaser: 'A special magazine from The Nightly featuring some of the most important business leaders from Australia and around the world.',
                                                        link: 'https://editions.thenightly.com.au/ccidist-replica-reader/?epub=https://editions.thenightly.com.au/ccidist-ws/the_nightly/the_nightly/issues/12678/#/pages/31',
                                                        imageURL: image,
                                                        lazyloadImages: true,
                                                        isLandingPage: true,
                                                    },
                                                }),
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        flex: {
                                                            default: {
                                                                hasBeforeAfterMargins:
                                                                    true,
                                                                flexDirection:
                                                                    'column',
                                                                gap: '40px',
                                                                alignItems:
                                                                    'center',
                                                            },
                                                            lg: {
                                                                hasBeforeAfterMargins:
                                                                    true,
                                                                flexDirection:
                                                                    'column',
                                                                gap: '80px',
                                                            },
                                                        },
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'ad-unit',
                                                                props: {
                                                                    adType: 'inline',
                                                                    noticePosition:
                                                                        'below-center',
                                                                    slot: {
                                                                        id: 'editions-page-1',
                                                                        size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                                                                    },
                                                                },
                                                            }),

                                                            layout.nestedComposition(
                                                                {
                                                                    type: 'box',
                                                                    props: {
                                                                        containerWidth:
                                                                            metrics
                                                                                .thenightly
                                                                                .siteMetrics
                                                                                .mainContentBodyWidth,
                                                                        flex: {
                                                                            default:
                                                                                {
                                                                                    flexDirection:
                                                                                        'column',
                                                                                    gap: '16px',
                                                                                },
                                                                            sm: {
                                                                                flexDirection:
                                                                                    'column',
                                                                                gap: '24px',
                                                                            },
                                                                            lg: {
                                                                                flexDirection:
                                                                                    'column',
                                                                                gap: '40px',
                                                                            },
                                                                        },
                                                                    },
                                                                    contentAreas:
                                                                        {
                                                                            main: [
                                                                                layout.nestedComposition(
                                                                                    {
                                                                                        type: 'component-overrider',
                                                                                        props: {
                                                                                            override:
                                                                                                () =>
                                                                                                    theNightlyTextOnlySectionHeaderOverride,
                                                                                        },
                                                                                        contentAreas:
                                                                                            {
                                                                                                children:
                                                                                                    [
                                                                                                        layout.component(
                                                                                                            {
                                                                                                                type: 'section-header',
                                                                                                                props: {
                                                                                                                    heading:
                                                                                                                        'More on Leadership',
                                                                                                                },
                                                                                                            },
                                                                                                        ),
                                                                                                    ],
                                                                                            },
                                                                                    },
                                                                                ),
                                                                                layout.component(
                                                                                    {
                                                                                        type: 'article-card-list',
                                                                                        props: {
                                                                                            expectedItemCount: 1,
                                                                                            dataDefinitionArgs:
                                                                                                {
                                                                                                    type: 'curation',
                                                                                                    name: 'thenightly-on-leadership',
                                                                                                    offset: 0,
                                                                                                    pageSize: 1,
                                                                                                },
                                                                                        },
                                                                                    },
                                                                                ),
                                                                                layout.component(
                                                                                    {
                                                                                        type: 'juliet',
                                                                                        props: {
                                                                                            cardType:
                                                                                                {
                                                                                                    type: 'landscape',
                                                                                                    format: 'landscape-common',
                                                                                                },
                                                                                            verticalSpacing:
                                                                                                'md',
                                                                                            hideByline:
                                                                                                false,
                                                                                            topicLevel:
                                                                                                'parent',
                                                                                            dataDefinitionArgs:
                                                                                                {
                                                                                                    type: 'curation',
                                                                                                    name: 'thenightly-on-leadership',
                                                                                                    offset: 1,
                                                                                                    pageSize: 14,
                                                                                                },
                                                                                        },
                                                                                    },
                                                                                ),
                                                                                layout.component(
                                                                                    {
                                                                                        type: 'nightly-button',
                                                                                        props: {
                                                                                            variant:
                                                                                                'default',
                                                                                            text: 'BACK TO TOP',
                                                                                            color: 'primary',
                                                                                            fill: 'text',
                                                                                            layout: {
                                                                                                alignSelf:
                                                                                                    'center',
                                                                                            },
                                                                                            action: {
                                                                                                type: 'button',
                                                                                                onClick:
                                                                                                    () =>
                                                                                                        window.scrollTo(
                                                                                                            {
                                                                                                                top: 0,
                                                                                                            },
                                                                                                        ),
                                                                                            },
                                                                                            icon: {
                                                                                                iconPosition:
                                                                                                    'right',
                                                                                                IconElement:
                                                                                                    (
                                                                                                        <IconChevronUpTN />
                                                                                                    ),
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                ),
                                                                            ],
                                                                        },
                                                                },
                                                            ),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
