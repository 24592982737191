import fetch from 'node-fetch'
import {
    AreaDto,
    PartyCode,
    PartyDto,
    SeatDto,
} from '@west-australian-newspapers/election-api-types'

export type InterknowlogyPartyCode = PartyCode | 'COL'

export interface InterknowlogyExtendedParty extends PartyDto {
    combinedCode: InterknowlogyPartyCode | undefined
    combinedPartyCodes: PartyCode[] | undefined
    shortPartyName: string | undefined
}

export interface InterknowlogyArea extends AreaDto {
    parties: InterknowlogyExtendedParty[]
}

export type InterknowlogyData = {
    areas: InterknowlogyArea[]
    seats: SeatDto[]
}

export const shortStateNames: Record<string, string> = {
    'Australian Capital Territory': 'ACT',
    'New South Wales': 'NSW',
    'Norhtern Territory': 'NT',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasminia: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',
}

export const shortPartyNames: Record<string, string> = {
    LNP: 'Liberal',
    ALP: 'Labor',
    GRN: 'Greens',
    ONP: 'One Nation',
}

/**
 * @returns a map from the interknowledgy API data source.
 * NOTE: This does also add an extra party to each area code which combines all the coalition parties.
 */
export const getInterknowledgyData = async (
    electionId: number,
): Promise<InterknowlogyData> => {
    const api = `https://elections.dev.swmdigital.io/data/${electionId}`

    return fetch(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(async (response) => {
        // response is okay, transform the data.
        if (response.ok && response.status === 200) {
            const data = (await response.json()) as InterknowlogyData

            // Now lets combine the coalitions
            data.areas.forEach((area) => {
                let coalitionParty: InterknowlogyExtendedParty | undefined =
                    undefined

                // Apply short party names
                area.parties.forEach((party) => {
                    const shortParty = Object.entries(shortPartyNames).find(
                        (keyValue) => keyValue[0] === party.partyCode,
                    ) ?? [party.partyCode, party.partyName]

                    party.shortPartyName = shortParty[1]
                })

                // Filter coalition party data!
                area.parties
                    .filter((party) => party.isCoalition)
                    .forEach((party) => {
                        if (!coalitionParty) {
                            // If it's not set, then set it
                            coalitionParty = { ...party }
                            coalitionParty.partyName = 'Coalition'
                            coalitionParty.shortPartyName = 'Coalition'
                            coalitionParty.combinedCode = 'COL'
                            coalitionParty.combinedPartyCodes = [
                                party.partyCode,
                            ]
                        } else {
                            // Else, add the values to the existing object
                            coalitionParty.seatsWon += party.seatsWon
                            coalitionParty.seatsAhead += party.seatsAhead
                            coalitionParty.seatsChanged += party.seatsChanged
                            coalitionParty.votePct += party.votePct // This might need some consideration
                            coalitionParty.voteCount += party.voteCount

                            // Only add the swing percentage if it's not null (component can be disabled if null swing)
                            if (party.swingPct) {
                                ;(coalitionParty.swingPct ?? 0) + party.swingPct // This might also need some consideration
                            }

                            // Add the party code to the combined party codes
                            coalitionParty.combinedPartyCodes?.push(
                                party.partyCode,
                            )
                        }
                    })

                // If one was found, add it to the parties array
                if (coalitionParty) {
                    area.parties.push(coalitionParty)
                }
            })

            // Return the first object in the array
            return data
        }

        // response is not okay, throw an error.
        throw new Error('Could not get data from Interknowledgy data source')
    })
}
