import React from 'react'
import { AllEvents, useFeatureValue } from '@news-mono/web-common'
import {
    ElectionText,
    ElectionTitleButton,
    TheRaceWinIndicator,
} from '../components'
import { ElectionData, getTopPartyData, TopPartyResults } from '../data'
import { TheRaceRow } from '../TheRaceRow/TheRaceRow'
import {
    TheRaceWidgetChevron,
    TheRaceWidgetContainer,
    TheRaceWidgetGraphContainer,
    TheRaceWidgetHeaderTitle,
    TheRaceWidgetGraphDataContainer,
    StyledRaceSpinner,
    TheRaceWidgetSpinnerContainer,
} from './TheRaceWidget.styled'
import { getInterknowledgyData } from '../data'
import { testFederalRaceData } from '../data/test-data'

export interface TheRaceWidgetProps {
    electionData: ElectionData
    partiesToShow?: number
    providedData?: TopPartyResults
    redirectTo?: string
    onEvent: (event: AllEvents) => void
}

export const TheRaceWidget = ({
    electionData,
    partiesToShow = 5,
    providedData,
    redirectTo,
    onEvent,
}: TheRaceWidgetProps) => {
    const electionId: number = useFeatureValue('2025-election-id') || 632

    const [isLoading, setLoading] = React.useState(providedData ? false : true)
    const [partyData, setPartyData] = React.useState<
        TopPartyResults | undefined
    >(providedData)
    const ref = React.useRef<HTMLDivElement>(null)

    // Get the interknowledgy data in a react useEffect to use for the party data
    React.useEffect(() => {
        const load = async () => {
            let data

            try {
                data = await getInterknowledgyData(electionId)
                // Set the party data
                setPartyData(
                    getTopPartyData({
                        electionData,
                        dataFeed: data,
                        partiesToShow,
                    }),
                )
                // Set loading false
                setLoading(false)
            } catch (error) {
                console.error('Error loading interknowlogy data:', error)
            }
        }

        if (!providedData) {
            setLoading(true)
            load()
        }
    }, [providedData, electionId, electionData, partiesToShow])

    // This is because the bar is 65% of the total seats, and we want to show the win indicator at the end.
    const TheRaceGraphData = isLoading ? LoadingData : LoadedData

    return (
        <TheRaceWidgetContainer>
            <ElectionTitleButton to={redirectTo}>
                <TheRaceWidgetHeaderTitle>The Race</TheRaceWidgetHeaderTitle>
                {redirectTo && <TheRaceWidgetChevron />}
            </ElectionTitleButton>
            <TheRaceWidgetGraphContainer ref={ref}>
                <TheRaceGraphData
                    partyData={partyData}
                    electionData={electionData}
                />
            </TheRaceWidgetGraphContainer>
            <ElectionText>
                Using current counted votes and data from X, we can predict the
                outcome of the election.
            </ElectionText>
        </TheRaceWidgetContainer>
    )
}

const LoadingData = () => {
    return (
        <TheRaceWidgetSpinnerContainer>
            <ElectionText>Loading...</ElectionText>
            <StyledRaceSpinner color={'black'} />
        </TheRaceWidgetSpinnerContainer>
    )
}

interface LoadedDataProps {
    partyData: TopPartyResults | undefined
    electionData: ElectionData
}
const LoadedData = ({ partyData, electionData }: LoadedDataProps) => {
    const totalBarLength = electionData.barTotalSeats
    const percentPerSeat = 100 / totalBarLength

    return (
        <>
            <TheRaceWinIndicator
                electionData={electionData}
                percentPerSeat={percentPerSeat}
            />
            <TheRaceWidgetGraphDataContainer>
                {/* Now implement each individual row! */}
                {partyData?.parties.map((party) => (
                    <TheRaceRow
                        party={party}
                        percentPerSeat={percentPerSeat}
                        seatsToWin={electionData.seatsToWin}
                    />
                ))}
            </TheRaceWidgetGraphDataContainer>
        </>
    )
}
