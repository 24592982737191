import React from 'react'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import { WebLink } from '@news-mono/web-common'
import {
    StyledNightlyEditionContainer,
    StyledNightlyEditionContent,
    StyledNightlyEditionPicture,
    StyledNightlyEditionHeading,
    StyledNightlyEditionTextContainer,
    StyledNightlyEditionSubHeading,
    StyledNightlyEditionTeaser,
    StyledNightlyEditionButtonContainer,
    Divider,
    StyledNightlyEditionLandingPageHeading,
} from '../SpecificEdition/NightlySpecificEdition.styled'
import { HeaderContainer, HeaderPrefix } from './SpecialEdition.styled'

export interface SpecialEditionProps {
    title: string
    teaser: string
    link: string
    imageURL: string
    lazyloadImages?: boolean
    isLandingPage?: boolean
}

export const SpecialEdition = ({
    title,
    teaser,
    link,
    imageURL,
    lazyloadImages,
    isLandingPage = false,
}: SpecialEditionProps) => {
    const NightlyEditionHeading = isLandingPage
        ? StyledNightlyEditionLandingPageHeading
        : StyledNightlyEditionHeading

    return (
        <>
            <StyledNightlyEditionContainer>
                <StyledNightlyEditionContent>
                    <StyledNightlyEditionPicture>
                        <WebLink to={link}>
                            <img
                                loading={lazyloadImages ? 'lazy' : undefined}
                                src={imageURL}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = require('../../ePaper/assets/paper.jpg')
                                }}
                                alt={'The Nightly Special Edition'}
                            />
                        </WebLink>
                    </StyledNightlyEditionPicture>
                    <HeaderContainer>
                        <HeaderPrefix>The Nightly On</HeaderPrefix>
                        <NightlyEditionHeading>
                            {title} Edition
                        </NightlyEditionHeading>
                    </HeaderContainer>
                    <StyledNightlyEditionTextContainer>
                        <StyledNightlyEditionSubHeading>
                            In this Edition
                        </StyledNightlyEditionSubHeading>
                        <StyledNightlyEditionTeaser>
                            {teaser}
                        </StyledNightlyEditionTeaser>
                    </StyledNightlyEditionTextContainer>
                    <StyledNightlyEditionButtonContainer>
                        <NightlyButton
                            variant={'default'}
                            text={'Read Edition'}
                            action={{
                                type: 'link',
                                to: link,
                            }}
                            color={'primary'}
                            fill={'filled'}
                            layout={{
                                shouldGrow: true,
                                alignSelf: 'stretch',
                            }}
                        />
                    </StyledNightlyEditionButtonContainer>
                    <StyledNightlyEditionTextContainer></StyledNightlyEditionTextContainer>
                </StyledNightlyEditionContent>
            </StyledNightlyEditionContainer>

            <Divider />
        </>
    )
}
