import styled from '@emotion/styled'
import React, { FormEvent } from 'react'
import { IconSearchTN } from '../../../icons'
import { getComponentTheme, calcRem } from '../../../__styling'
import {
    DataLayerEventName,
    SearchEvent,
    useQueryParams,
} from '@news-mono/web-common'

type SearchInputProps = {
    value: string
    onChange: (value: string) => void
    onSubmit: () => void
    onEvent: (event: SearchEvent) => void
    queryParams: URLSearchParams
    pathname: string
}
/**
 * Reusable Search Input component for the Election Widget.
 *
 * @param value - The current value of the search input.
 * @param onChange - The function to call when the input value changes.
 *
 * @returns {JSX.Element} The search input component.
 */
export const ElectionSearchInput = ({
    value,
    onChange,
    onSubmit,
    onEvent,
    queryParams,
    pathname,
}: SearchInputProps) => {
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        onEvent({
            type: DataLayerEventName.search,
            originator: 'ElectionSearch',
            payload: {
                searchTerm: value,
            },
        })

        // Get the current query param value (if any)
        const currentQueryValue = queryParams.get('search')

        // Only update the URL if the search value has changed since last search
        const shouldUpdateHistory = currentQueryValue
            ? currentQueryValue != value.toLowerCase()
            : true

        // Finally, update query params
        value
            ? queryParams.set('search', value.toLowerCase())
            : queryParams.delete('search')

        // Update the URL if required
        if (shouldUpdateHistory) {
            const url = pathname + '?' + queryParams.toString()
            window.history.pushState({ path: url }, '', url)
        }

        onSubmit()
    }

    return (
        <StyledElectionInput onSubmit={handleSubmit}>
            <IconSearchTN />
            <input
                placeholder="Search electorate or candidate"
                tabIndex={0}
                value={value}
                minLength={1}
                maxLength={1000}
                onChange={(e) => onChange(e.target.value)}
            />
        </StyledElectionInput>
    )
}
const StyledElectionInput = styled.form(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return {
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(16),
        background: electionTheme.input.backgroundColor,
        color: electionTheme.input.textColor,
        borderRadius: calcRem(8),
        padding: calcRem(8, 16),
        outline: `1px solid ${electionTheme.input.outlineColor}`,

        input: {
            background: 'inherit',
            border: 'none',
            outline: 'none',
            flexGrow: 1,
            lineHeight: calcRem(24),
            letterSpacing: '-2%',
        },

        ['&:is(:hover, :focus-within)']: {
            outline: `1px solid ${electionTheme.input.outlineHoverColor}`,
        },
        transition: 'all 0.2s',
    }
})
