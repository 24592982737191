import { TheNightlySection } from '@news-mono/common'
import { StaticRoutes } from '@news-mono/web-common'
import { getUserSettingRouteInfo } from './user-setting'
import { theNightlyPageIsUnavailableRoute } from './errors'
import { homepage } from './home'
import { staticContentRoutes } from './static/static-content-routes'
import { theNightlyEmailVerifiedRoute } from './tn-warden/email-verified'
import { theNightlySignupSuccessRoute } from './tn-warden/signup-success'
import { theNightlyAccountDeletedRoute } from './tn-warden/account-deleted'
import { puzzles } from './puzzles'
import { ePaper } from './ePaper'
import { olympicContentRoutes } from './olympics/olympic-content-routes'
import { electionRoutes } from './elections/routes'

export const staticRoutes: StaticRoutes<TheNightlySection> = {
    ...staticContentRoutes,
    ...homepage,
    ...puzzles,
    ...ePaper,
    ...theNightlyPageIsUnavailableRoute,
    ...getUserSettingRouteInfo,
    ...theNightlyEmailVerifiedRoute,
    ...theNightlySignupSuccessRoute,
    ...theNightlyAccountDeletedRoute,
    ...olympicContentRoutes,
    ...electionRoutes,
}
