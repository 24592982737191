import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheNightlySection } from '@news-mono/common'
import { StaticRoutes } from '@news-mono/web-common'
import { createElectionPage } from './createElectionPage'

export type ElectionType =
    | {
          type: 'state'
          state: 'act' | 'nsw' | 'nt' | 'qld' | 'sa' | 'tas' | 'vic' | 'wa'
      }
    | {
          type: 'federal'
      }

export const createElectionRoutes = (
    electionType: ElectionType,
    year: number,
): StaticRoutes<TheNightlySection> => {
    if (electionType.type === 'federal') {
        return {
            [`/politics/elections/federal/${year}`]: ({
                getAdTargeting,
                store,
            }) => {
                const enableRoute = isFeatureEnabled(
                    toFeatureState(store.getState().toggles),
                    'election-root-page',
                )

                if (!enableRoute) {
                    return null
                }

                return createElectionPage({
                    heading: `${year} Federal Election`,
                    description: 'TODO',
                    getAdTargeting,
                })
            },
        }
    } else {
        return {
            [`/politics/elections/state/${electionType.state}/${year}`]: ({
                getAdTargeting,
                store,
            }) => {
                const enableRoute = isFeatureEnabled(
                    toFeatureState(store.getState().toggles),
                    'election-root-page',
                )

                if (!enableRoute) {
                    return null
                }

                return createElectionPage({
                    heading: `${year} ${electionType.state.toUpperCase()} State Election`,
                    description: 'TODO',
                    getAdTargeting,
                })
            },
        }
    }
}
