import { TopPartyDataProps, TopPartyResults } from './'

// Combine all state data types here for the check!
export type StateElectionDataType = WaStateElectionDataType

export type RegisteredElectionState = 'WA'

// Public facing function to get the top party data for the specific state election
interface StateTopPartyDataProps extends TopPartyDataProps {
    electionData: StateElectionDataType
}
export const getStateElectionPartyData = (
    props: StateTopPartyDataProps,
): TopPartyResults | undefined => {
    // Built in this way to allow for future expansion on other state elections!

    // Handle WA state election data here!
    if (isWaStateElectionData(props.electionData)) {
        return getWaStateElectionPartyData({
            ...props,
            electionData: props.electionData,
        })
    } else {
        throw new Error('Invalid State Election Data')
    }
}

export const getSpecificStateElectionData = (
    state: RegisteredElectionState,
): StateElectionDataType => {
    if (state === 'WA') {
        return waStateElectionData
    } else {
        throw new Error('Invalid State Election Data')
    }
}

// -------------------------- WA STATE ELECTION DATA --------------------------

// WA party data:
type WaStateElectionDataType = {
    type: 'state-wa'
    seatsTotal: number
    seatsToWin: number
    barTotalSeats: number
}
export const waStateElectionData = {
    type: 'state-wa',
    seatsTotal: 59,
    seatsToWin: 30,
    barTotalSeats: 45,
} as const

const isWaStateElectionData = (
    electionData: StateElectionDataType,
): electionData is WaStateElectionDataType => {
    return electionData.type === 'state-wa'
}

interface WaStateTopPartyDataProps extends TopPartyDataProps {
    electionData: WaStateElectionDataType
}
const getWaStateElectionPartyData = ({
    partiesToShow,
    sortByPredicted = true,
}: WaStateTopPartyDataProps): TopPartyResults | undefined => {
    // TODO: Implement this?
    return
}
