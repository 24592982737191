import {
    createRegisterableComponent,
    DataLayerEventName,
    useQueryParams,
} from '@news-mono/web-common'
import React from 'react'
import { ElectionFilter } from '../../widgets/election/components'
import { ElectionSearchInput } from '../../widgets/election/components/ElectionSearch'
import { seatFilterItems, sortMethods } from '../../widgets/election/data'
import { FederalElection2025Logo } from './Logo'

//demo page component to pop components onto
export const NightlyFedElection2025Page = createRegisterableComponent(
    'nightly-fed-election-2025-page',
    (props, services) => {
        const [searchValue, setSearchValue] = React.useState('')
        const { queryParams, pathname } = useQueryParams()

        return (
            <div
                style={{
                    backgroundColor: '#F4F4F4',
                    borderRadius: '12px',
                    padding: '24px',
                    margin: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                    }}
                >
                    Small
                    <FederalElection2025Logo size="small" />
                    Large
                    <FederalElection2025Logo size="large" />
                    Stacked
                    <FederalElection2025Logo size="stacked" />
                    Switch
                    <FederalElection2025Logo size="switch" />
                </div>
                <div>
                    <ElectionSearchInput
                        onEvent={services.onEvent}
                        value={searchValue}
                        onChange={(value) => setSearchValue(value)}
                        onSubmit={() => console.log('submitting')}
                        queryParams={queryParams}
                        pathname={pathname}
                    />
                    <ElectionFilter
                        headingText="Sort by"
                        queryFilterParam="sort"
                        dataLayerEventOriginator="ElectionSort"
                        filterItems={sortMethods}
                        onEvent={services.onEvent}
                        dataLayerEventName={DataLayerEventName.searchFilter}
                        defaultParamName="latest"
                        queryParams={queryParams}
                        pathname={pathname}
                    />
                    <ElectionFilter
                        headingText="State"
                        queryFilterParam="state"
                        dataLayerEventOriginator="ElectionStateFilter"
                        filterItems={seatFilterItems}
                        onEvent={services.onEvent}
                        dataLayerEventName={DataLayerEventName.searchFilter}
                        queryParams={queryParams}
                        pathname={pathname}
                        defaultParamName="all"
                    />
                </div>
            </div>
        )
    },
)
