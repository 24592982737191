import React from 'react'
import { URLSearchParams } from 'url'
import {
    FilterEvent,
    FilterQueryParam,
    getEventName,
} from '../../../result-filters'
import { DataLayerEventName } from '@news-mono/web-common'
import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, TheNightlyThemeConfig } from '../../../__styling'

type FilterProps = {
    /** Data Layer event function */
    onEvent: (event: FilterEvent) => void
    /** Name of the Data Layer event */
    dataLayerEventName:
        | DataLayerEventName.searchFilter
        | DataLayerEventName.savedArticlesFilter
    /** The text to show above the filter, describes the purpose of the filter */
    headingText: string
    /** The query parameter name to show in the URL */
    queryFilterParam: string
    /** The Originator field used in the onEvent function */
    dataLayerEventOriginator: string
    /** The items to render in this component. `displayName` will appear on the page, `paramName` appears in the query params */
    filterItems: FilterQueryParam[]
    /** The name of the param which is the default state */
    defaultParamName: string
    /** Query params object */
    queryParams: URLSearchParams
    /** Current URL */
    pathname: string
}

export const ElectionFilter = ({
    headingText,
    queryFilterParam,
    dataLayerEventOriginator,
    onEvent,
    dataLayerEventName,
    filterItems,
    defaultParamName,
    queryParams,
    pathname,
}: FilterProps) => {
    const [selectedItem, setSelectedItem] =
        React.useState<string>(defaultParamName)

    /** Updates query params and fires required Data Layer event */
    const onItemClicked = (listItem: string, defaultParamName: string) => {
        if (listItem === defaultParamName) {
            // We dont want to show any query params with the default `All` option selected
            queryParams.delete(queryFilterParam)
            setSelectedItem(defaultParamName)
        } else {
            queryParams.set(queryFilterParam, listItem)
            setSelectedItem(listItem)
        }

        const url = pathname + '?' + queryParams.toString()
        window.history.pushState({ path: url }, '', url)

        const eventName = getEventName(queryFilterParam)

        if (eventName) {
            onEvent({
                type: dataLayerEventName,
                originator: dataLayerEventOriginator,
                payload: {
                    name: eventName,
                    value: queryParams.get(queryFilterParam) ?? listItem,
                },
            })
        }
    }

    return (
        <FilterContainer>
            <StyledLabel>{headingText}</StyledLabel>
            <LinkList>
                {filterItems.map((item, i) => (
                    <FilterItem
                        key={i}
                        className={
                            item.paramName === selectedItem ? 'active' : ''
                        }
                        onClick={() =>
                            onItemClicked(item.paramName, defaultParamName)
                        }
                    >
                        {item.displayName}
                    </FilterItem>
                ))}
            </LinkList>
        </FilterContainer>
    )
}

const { neutral } = tokens.thenightly.colors.palette

export const FilterContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
})

export const StyledLabel = styled.label(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
)

export const LinkList = styled.nav({
    display: 'flex',
    margin: 0,
    padding: 0,
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: calcRem(8),
    width: '100%',
})

export const FilterItem = styled.button(({ theme }) => {
    return [
        {
            // Reset default button styles
            border: 'none',
            background: 'none',

            cursor: 'pointer',
            padding: calcRem(4, 12),
            borderRadius: calcRem(40),
            outline: `${calcRem(1)} solid ${neutral[30]}`,
            outlineOffset: calcRem(-1),

            '&.active': {
                backgroundColor: neutral[100],
                outlineColor: neutral[100],
                color: neutral[0],

                ['&:is(:focus,:active)']: {
                    outlineColor: neutral[30],
                },
            },
            ['&:is(:focus,:active)']: {
                outlineColor: neutral[100],
            },
        },
        textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
    ]
})
