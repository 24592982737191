import { TopPartyDataProps, TopPartyResults } from './'

// Election Data
export type FederalElectionDataType = {
    type: 'federal'
    seatsTotal: number
    seatsToWin: number
    barTotalSeats: number
}
export const federalElectionData = {
    type: 'federal',
    seatsTotal: 150,
    seatsToWin: 76,
    barTotalSeats: 100,
} as const

interface FederalTopPartyDataProps extends TopPartyDataProps {
    electionData: FederalElectionDataType
}
export const getFederalElectionPartyData = ({
    partiesToShow,
    dataFeed,
    sortByPredicted = true,
}: FederalTopPartyDataProps): TopPartyResults | undefined => {
    // TODO: Implement this, if it's needed?
    return undefined
}
