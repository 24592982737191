import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { IconChevronRightTN } from '../../../icons/IconChevronRightTN/IconChevronRightTN'

export const TheSeatsWidgetContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(8),
    alignSelf: 'stretch',

    padding: calcRem(16),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})

export const TheSeatsWidgetHeaderTitle = styled('h2')({
    textAlign: 'right',
    margin: 0,

    fontFamily: 'Inter',
    fontSize: calcRem(40),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: calcRem(48),
    letterSpacing: calcRem(-1.2),
})

export const TheSeatsWidgetChevron = styled(IconChevronRightTN)({})
