import { createRegisterableComponent, ShareOption } from '@news-mono/web-common'
import React from 'react'
import { WAStateElection2025Logo } from './Logo'

//demo page component to pop components onto
export const NightlyWAState2025Page = createRegisterableComponent(
    'nightly-wa-state-election-2025-page',
    (props, services) => {
        return (
            <div
                style={{
                    backgroundColor: '#F4F4F4',
                    borderRadius: '12px',
                    padding: '24px',
                    margin: '20px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                    }}
                >
                    Small
                    <WAStateElection2025Logo size="small" />
                    Large
                    <WAStateElection2025Logo size="large" />
                    Stacked
                    <WAStateElection2025Logo size="stacked" />
                    Switch
                    <WAStateElection2025Logo size="switch" />
                </div>
            </div>
        )
    },
)
