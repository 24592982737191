import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TheRaceWidget, TheRaceWidgetProps } from './TheRaceWidget'

export const TheRaceWidgetRegistration = createRegisterableComponent(
    'election-the-race-widget',
    (props: TheRaceWidgetProps, { onEvent }) => {
        return <TheRaceWidget {...props} onEvent={onEvent} />
    },
)
