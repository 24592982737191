import {
    AreaDto,
    SeatDto,
} from '@west-australian-newspapers/election-api-types'
import { FilterQueryParam } from '../../../result-filters'

export const seatFilterItems: FilterQueryParam[] = [
    {
        displayName: 'All',
        paramName: 'all',
    },
    {
        displayName: 'ACT',
        paramName: 'act',
    },
    {
        displayName: 'NSW',
        paramName: 'nsw',
    },
    {
        displayName: 'NT',
        paramName: 'nt',
    },
    {
        displayName: 'QLD',
        paramName: 'qld',
    },
    {
        displayName: 'SA',
        paramName: 'sa',
    },
    {
        displayName: 'TAS',
        paramName: 'tas',
    },
    {
        displayName: 'VIC',
        paramName: 'vic',
    },
    {
        displayName: 'WA',
        paramName: 'wa',
    },
]

export const seatSortItems = [
    'Latest',
    'Alphabetical (descending)',
    'Alphabetical (ascending)',
]

export type SeatFilterOptions = typeof seatFilterItems

export const sortMethods: FilterQueryParam[] = [
    {
        displayName: 'Latest',
        paramName: 'latest',
    },
    {
        displayName: 'Alphabetical (ascending)',
        paramName: 'a-z',
    },
    {
        displayName: 'Alphabetical (descending)',
        paramName: 'z-a',
    },
]

export type SeatSortOptions = typeof sortMethods

/** Filters the provided Areas based on the provided filter param.
 * i.e. passing in a filter of `WA` would only return areas with an `areacode` of WA.
 */
export const filterAreasByState = (
    areas: AreaDto[],
    filter: SeatFilterOptions[number],
): AreaDto[] => {
    return filter.displayName === 'All'
        ? areas
        : areas.filter((area) => area.areaCode === filter.displayName)
}

/** Searches the provided array of seats for matches on `SeatName`, or Candidate `FirstName/LastName` */
export const searchSeatsOrCandidates = (
    seats: SeatDto[],
    searchTerm: string,
): SeatDto[] => {
    return seats.filter(
        (seat) =>
            seat.seatName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            seat.candidates.some((candidate) => {
                return (
                    candidate.firstName
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    candidate.lastName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                )
            }),
    )
}

/** Sorts the provided array of seats based on the provided sort method */
export const sortSeats = (seats: SeatDto[], sort: SeatSortOptions[number]) => {
    return seats.sort((a, b) => {
        if (sort.paramName === 'a-z') {
            return a.seatName.localeCompare(b.seatName)
        } else if (sort.paramName === 'z-a') {
            return b.seatName.localeCompare(a.seatName)
        } else return 0 // latest
        // Currently the API does not have any concept of latest, this is being investigated.
        // For now, lets just return the seats as they are provided from the API (assuming this will be the default in future)
    })
}
