import React from 'react'
import { SeatCard } from '../SeatCard/SeatCard'
import {
    TheSeatsWidgetContainer,
    TheSeatsWidgetHeaderTitle,
} from './TheSeatsWidget.styled'
import { getInterknowledgyData, getSeatsData, SeatData } from '../data'
import { useFeatureValue } from '@news-mono/web-common'

export interface SeatsWidgetProps {}

/**
 * Placeholder showing a few cards using test data; this will be replaced with a list of cards retrieved from the API
 */
export const TheSeatsWidget: React.FC = () => {
    const electionId: number = useFeatureValue('2025-election-id') || 632

    const [seatData, setSeatData] = React.useState<SeatData[] | null>(null)

    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        const load = async () => {
            let data

            try {
                data = await getInterknowledgyData(electionId)
                setSeatData(getSeatsData(data))
            } catch (error) {
                console.error('Error loading interknowlogy data:', error)
            }
        }

        if (isLoading) {
            load()
            setIsLoading(false)
        }
    }, [electionId, isLoading])

    return (
        <>
            <TheSeatsWidgetContainer>
                <TheSeatsWidgetHeaderTitle>The Seats</TheSeatsWidgetHeaderTitle>
            </TheSeatsWidgetContainer>
            {seatData
                ? seatData
                      .slice(0, 10)
                      .map((seat) => <SeatCard key={seat.seatId} seat={seat} />)
                : Array(5) // Render 5 skeletons while loading
                      .fill(0)
                      .map((_, index) => <SeatCard key={index} isLoading />)}
        </>
    )
}
