import { TheNightlySection } from '@news-mono/common'
import {
    getFederalElectionData,
    metrics,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType } from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectionPageArgs = {
    heading: string
    description: string
    getAdTargeting: GetRouteAdTargeting
}

export const createElectionPage = ({
    heading,
    description,
    getAdTargeting,
}: CreateElectionPageArgs): PageType<TheNightlySection> => {
    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'politics',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'scheme-overrider',
                props: {
                    override: sectionThemeOverrider('thenightly', 'politics'),
                },
                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                containerWidth:
                                    metrics.thenightly.siteMetrics
                                        .mainContentWidth,
                                fullWidth: true,
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'election-the-race-widget',
                                        props: {
                                            electionData:
                                                getFederalElectionData(),
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-seat-widget',
                                        props: {}, //@todo, cleanup temporary Seats Widget
                                    }),
                                    layout.component({
                                        type: 'nightly-fed-election-2025-page',
                                        props: {},
                                    }),
                                    layout.component({
                                        type: 'nightly-wa-state-election-2025-page',
                                        props: {
                                            linkTo: '/politics/wa-election-2025',
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
