import styled from '@emotion/styled'
import { calcRem } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { keyframes } from '@emotion/css'

export const SeatContainer = styled('div')(
    ({ isLoading }: { isLoading: boolean }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minHeight: calcRem(124),
        height: isLoading ? calcRem(124) : 'auto',
        minWidth: calcRem(342),
        maxWidth: calcRem(558),
        padding: isLoading ? 0 : calcRem(8),
        gap: calcRem(8),
        background: tokens.thenightly.colors.palette.white,
        margin: calcRem(16),
        borderRadius: calcRem(12),
        border: `${calcRem(1)} solid ${
            tokens.thenightly.colors.palette.neutral[20]
        }`,
    }),
)

export const SeatHeaderContainer = styled('div')({
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: calcRem(8),
    maxHeight: calcRem(24),
})

export const SeatDetailsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(4),
    maxHeight: calcRem(24),
    margin: 0,
})

interface SeatDetailsTextProps {
    variant: 'name' | 'state'
}

export const SeatDetailsText = styled('p')<SeatDetailsTextProps>(
    ({ variant }) => ({
        margin: 0,
        fontSize: calcRem(16),
        fontWeight: variant === 'name' ? 700 : 500,
        color:
            variant === 'name'
                ? tokens.thenightly.colors.palette.neutral[100]
                : tokens.thenightly.colors.palette.neutral[70],
        lineHeight: calcRem(24),
    }),
)

interface SeatStatusContainerProps {
    color: string
}

export const SeatStatusContainer = styled('div')<SeatStatusContainerProps>(
    ({ color }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: calcRem(16),
        background: color,
        padding: `${calcRem(4)} ${calcRem(12)}`,

        borderRadius: calcRem(40),
    }),
)

export const SeatsStatusText = styled('p')({
    margin: 0,
    fontSize: calcRem(12),
    fontWeight: 400,
    lineHeight: calcRem(16),
    color: tokens.thenightly.colors.palette.neutral[0],
})

export const SeatResultsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(4),
    alignSelf: 'stretch',
})

export const SeatVotesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    height: calcRem(24),
})

interface SeatVoteCountProps {
    color: string
}

export const SeatVoteCountContainer = styled('div')<SeatVoteCountProps>(
    ({ color }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: calcRem(8),
        color,
    }),
)

interface SeatVoteCountTextProps {
    variant: 'percentage' | 'count'
}

export const SeatVoteCountText = styled('p')<SeatVoteCountTextProps>(
    ({ variant }) => ({
        fontSize: calcRem(16),
        fontWeight: variant === 'percentage' ? 700 : 500,
    }),
)

export const SeatCandidatesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    maxHeight: calcRem(36),
})

export const SeatCandidateContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(8),
})

export const SeatCandidateImage = styled('img')({
    width: calcRem(32),
    height: calcRem(32),
    borderRadius: calcRem(60),
    backgroundRepeat: 'no-repeat',
})

interface SeatCandidateDetailsProps {
    reverse: boolean
}

export const SeatCandidateDetails = styled('div')<SeatCandidateDetailsProps>(
    ({ reverse }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxHeight: calcRem(32),
        paddingTop: calcRem(4),
        alignItems: reverse ? 'flex-end' : 'flex-start',
        textAlign: reverse ? 'right' : 'left',
        flex: `1 0 0`,
    }),
)

interface SeatCandidateTextProps {
    incumbent: boolean
}

export const SeatCandidateNameText = styled('p')<SeatCandidateTextProps>(
    ({ incumbent }) => ({
        margin: 0,
        fontSize: calcRem(12),
        fontWeight: incumbent ? 700 : 400,
        lineHeight: calcRem(16),
    }),
)

interface SeatCandidatePartyTextProps {
    color: string
}

export const SeatCandidatePartyText = styled('p')<SeatCandidatePartyTextProps>(
    ({ color }) => ({
        margin: 0,
        fontSize: calcRem(12),
        fontWeight: 600,
        color,
        lineHeight: calcRem(16),
    }),
)

export const SeatBarGraphContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    margin: 4,
    height: calcRem(8),
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: calcRem(4),
    background: tokens.thenightly.colors.palette.white,
    borderRadius: calcRem(4),
})

export const SeatBarGraphDivider = styled('div')({
    margin: 0,
    position: 'absolute',
    left: '50%',
    top: `-${calcRem(4)}`,
    bottom: 0,
    width: calcRem(4),
    height: calcRem(16),
    backgroundColor: tokens.thenightly.colors.palette.neutral[70],
    border: `${calcRem(1)} solid ${tokens.thenightly.colors.palette.white}`,
})

interface SeatBarGraphSlidingBarProps {
    candidateOneColor: string
    candidateTwoColor: string
    partyOnePct: number | null
}

export const SeatBarGraphSlidingBar = styled(
    'div',
)<SeatBarGraphSlidingBarProps>(
    ({ candidateOneColor, candidateTwoColor, partyOnePct }) => ({
        width: '100%',
        height: calcRem(8),
        background: `linear-gradient(
        to right,
        ${candidateOneColor} ${partyOnePct}%,
        ${candidateTwoColor} ${partyOnePct}% 100%
    )`,
        position: 'relative',
    }),
)

// Skeletons

const pulseAnimation = keyframes`
    0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: -135% 0%;
      }
`

export const SkeletonBase = styled('div')({
    margin: 0,
    width: '100%',
    height: '100%',
    borderRadius: calcRem(12),
    background: `linear-gradient(90deg, ${tokens.thenightly.colors.palette.neutral[20]} 0%, ${tokens.thenightly.colors.palette.neutral[10]} 50%, ${tokens.thenightly.colors.palette.neutral[20]} 100%)`,
    backgroundSize: '400% 100%',
    animation: `${pulseAnimation} 1.5s infinite linear`,
})
