import React, { FC, useEffect, useRef, useState } from 'react'
import {
    StyledAnimatedHeaderNavContainer,
    StyledHeaderNavContainer,
    StyledNavAdContainer,
} from './AnimatedHeaderNav.styled'
import {
    TheNightlyHeaderNavState,
    TNQuickLinks,
} from '@news-mono/component-library'
import { CollectionEvent, NavEvent, NavItem } from '@news-mono/web-common'
import { CSSTransition } from 'react-transition-group'
import { headerVisibleStates } from '../useTheNightlyHeaderNav/constants'
import { NewsTicker } from '../../../publication/NewsTicker/NewsTicker'

interface AnimatedHeaderNavProps {
    navState: TheNightlyHeaderNavState
    navigation: JSX.Element
    navItems: NavItem[]
    onEvent: (event: NavEvent | CollectionEvent) => void
    adComponent?: JSX.Element
    height: number
    innerRef: (ref: any) => void
}

const TRANSITION_TIME = 300

export const AnimatedHeaderNav: FC<AnimatedHeaderNavProps> = ({
    navState,
    navigation,
    navItems,
    onEvent,
    adComponent,
    height,
    innerRef,
}) => {
    useEffect(() => {
        window.addEventListener('scroll', detectScroll, { passive: true })
        return () => {
            window.removeEventListener('scroll', detectScroll)
        }
    })

    const ref = useRef(null)

    const { adHeight, navDisplayState, isMobile } = navState

    const isAdVisible = navDisplayState === 'header-with-ad' && !isMobile
    const isHeaderNavVisible = headerVisibleStates.includes(navDisplayState)
    const isHeaderWithoutContentNav =
        navDisplayState === 'header-with-ad' || navDisplayState === 'header'

    //detect first scroll to restore usual  behaviour
    const [hasScrolled, setHasScrolled] = useState(false)
    const detectScroll = () => {
        setHasScrolled(true)
    }

    return (
        <CSSTransition
            //begin in active state to allow header ad to register, return to regualar transitions after scroll
            in={hasScrolled ? isAdVisible : true}
            timeout={TRANSITION_TIME}
            classNames="header-with-ad"
            nodeRef={ref}
        >
            <CSSTransition
                in={isHeaderNavVisible}
                timeout={TRANSITION_TIME}
                classNames="header"
                enter={isHeaderWithoutContentNav}
                nodeRef={ref}
            >
                <StyledAnimatedHeaderNavContainer
                    adHeight={adHeight}
                    navHeight={height}
                    navDisplayState={navDisplayState}
                    transitionTime={TRANSITION_TIME}
                    ref={ref}
                >
                    <StyledNavAdContainer className="header-navigation">
                        {adComponent}
                    </StyledNavAdContainer>
                    <StyledHeaderNavContainer
                        className="header-navigation"
                        ref={innerRef}
                    >
                        {navigation}
                        <TNQuickLinks links={navItems} onEvent={onEvent} />
                        <NewsTicker onEvent={onEvent} />
                    </StyledHeaderNavContainer>
                </StyledAnimatedHeaderNavContainer>
            </CSSTransition>
        </CSSTransition>
    )
}
